import React, { useState, useEffect, useCallback, useRef } from 'react';
import { graphql } from 'gatsby';
import Dashboards from '../components/DigitalDashboard';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import AES from '../../lib/gibberish-aes';

const Page = ({ data }) => {
  const [pass, setPass] = useState('');
  const [passSuccess, setPassSuccess] = useState(false);
  const [url, setUrl] = useState(data.allAllDashboardPagesHJson.edges[0].node.iframe);
  const inputRef = useRef();
  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus();
    }
  }, []);

  const checkPass = useCallback(
    (event) => {
      event.preventDefault();
      // console.log(url);
      if (url) {
        try {
          var path = AES.dec(url, pass);
        } catch (e) {
          path = '';
        }
        // console.log(path, url, pass);//USOilAnalytics
        if (path.indexOf('https') === 0) {
          //give the browser a prompt to save the password
          history.pushState({}, '', '?success=true');

          setUrl(path);
          setPassSuccess(true);
        } else {
          setPass('');
        }
      }
    },
    [url, pass, inputRef, setUrl, setPassSuccess]
  );

  if (data.allAllDashboardPagesHJson.edges[0].node) {
    const page = data.allAllDashboardPagesHJson.edges[0].node;

    return (
      <Layout>
        <Helmet>
          <title>{page.meta_title}</title>
          <meta name="description" content={page.meta_desc} />
          <meta property="og:description" content={page.meta_desc} />
          <meta name="robots" content="noindex" />
        </Helmet>
        {passSuccess ? (
          <Dashboards className="digital-page" fields={{ ...page, url: url }} />
        ) : (
          <div
            style={{ width: '300px', margin: '220px auto 110px auto', border: '2px solid white', padding: '1em 2em' }}
          >
            Password:{' '}
            <form onSubmit={checkPass}>
              <input
                ref={inputRef}
                type="password"
                style={{
                  fontSize: '1.25em',
                  border: 'none',
                  padding: '0.5em',
                  width: '180px',
                  verticalAlign: 'middle',
                }}
                value={pass}
                autoComplete="password"
                onChange={(event) => setPass(event.target.value)}
              />
              <input
                type="text"
                style={{ position: 'absolute', marginLeft: '-10000px' }}
                readOnly={true}
                value="default"
                autoComplete="username"
              />
              <input
                type="submit"
                style={{ display: 'inline-block', width: '50px', marginLeft: '1em', height: '43px' }}
                value="ok"
              />
            </form>
          </div>
        )}
      </Layout>
    );
  } else {
    return <></>;
  }
};

export default Page;

export const pageQuery = graphql`
  query DashboardPageById($id: String!) {
    allAllDashboardPagesHJson(filter: { id: { eq: $id } }) {
      edges {
        node {
          meta_title
          meta_desc
          title
          iframe
          acf {
            hero_image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1920)
              }
            }
          }
        }
      }
    }
  }
`;
