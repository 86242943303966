import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Dashboard = styled.div`
  padding: 63% 0 0 0;
  position: relative;
`;

const WrapperDiv = styled.div`
  @media (min-width: 901px) {
    margin-top: 110px;
  }
  position: relative;
  min-height: 170px;
  margin-top: 110px;
  .gatsby-image-wrapper {
    height: 170px;
  }
`;

const Title1 = styled.div`
  position: absolute;
  font-family: 'Oswald';
  font-size: 23px;
  letter-spacing: 4px;
  text-transform: uppercase;
  top: 38px;
  left: 199px;

  @media (max-width: 900px) {
    left: 20px;
  }
`;

const Title2 = styled.div`
  position: absolute;
  font-family: 'Oswald';
  /* font-size: 73px; */
  letter-spacing: 3px;
  text-transform: uppercase;
  top: 62px;
  left: 196px;
  line-height: 1.2;
  font-size: calc(28px + (73 - 28) * ((100vw - 500px) / (1920 - 500)));

  @media (max-width: 900px) {
    left: 20px;
  }
`;

const DigitalDashboard = ({ fields }) => {
  return (
    <>
      <WrapperDiv>
        <GatsbyImage
          image={fields.acf.hero_image.childImageSharp.gatsbyImageData}
          alt="header image"
          imgStyle={{ objectPosition: 'center center' }}
        />

        <Title1>Digital Dashboard</Title1>
        <Title2>{fields.title.replace(/Digital Dashboard: /i, '')}</Title2>
      </WrapperDiv>

      <Dashboard className="digital-dashboard-wrapper">
        <iframe
          src={fields.url}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          frameBorder="0"
          allow="fullscreen"
        />
      </Dashboard>
    </>
  );
};

export default DigitalDashboard;
